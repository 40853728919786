<template>
  <div>
    <b-card>
      <div>
        <!-- Table Fitler -->
        <div>
          <b-row class="my-1">
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <b-form-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                size="sm"
                class="per-page-selector d-inline-block mx-50"
                style="width: 100px;"
              />
            </b-col>
            <!-- Search -->
            <b-col>
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="searchFilter"
                  type="search"
                  placeholder="검색: 상위/추천, 닉네임/이름, 아이디, 전화번호 ..."
                  style="color: #99dff1"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!searchFilter"
                    @click="searchFilter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </div>
        <!-- table content -->
        <div>
          <b-table
            responsive
            show-empty
            hover
            small
            empty-text="No matching records found"
            style="text-align: center; font-size: small"
            head-variant="light"
            :items="paginatedItems"
            :fields="branchFields"
          >
            <template #thead-top>
              <b-tr>
                <!-- 일반적인 헤더 컬럼 -->
                <b-th
                  rowspan="2"
                  class="align-middle"
                >
                  No.
                </b-th>
                <b-th
                  rowspan="2"
                  class="align-middle"
                >
                  권한
                </b-th>
                <b-th
                  rowspan="2"
                  class="align-middle"
                >
                  아이디/닉네임
                </b-th>
                <b-th
                  rowspan="2"
                  class="align-middle"
                >
                  상위
                </b-th>
                <b-th
                  rowspan="2"
                  class="align-middle"
                >
                  통합캐쉬
                </b-th>
                <b-th
                  rowspan="2"
                  class="align-middle"
                >
                  포인트
                </b-th>
                <b-th
                  colspan="2"
                  style="border-left: solid 2px #3b4253 !important;"
                >
                  루징
                </b-th>
                <b-th
                  colspan="2"
                  style="border-left: solid 2px #3b4253 !important;"
                >
                  롤링(스포츠)
                </b-th>
                <b-th
                  colspan="2"
                  style="border-left: solid 2px #3b4253 !important;"
                >
                  롤링(카지노)
                </b-th>
                <b-th
                  colspan="2"
                  style="border-left: solid 2px #3b4253 !important;"
                >
                  롤링(슬롯)
                </b-th>
                <b-th
                  rowspan="2"
                  class="align-middle"
                >
                  회원수
                </b-th>
                <b-th
                  rowspan="2"
                  class="align-middle"
                >
                  회원
                </b-th>
                <b-th
                  rowspan="2"
                  class="align-middle"
                >
                  정산
                </b-th>
                <b-th
                  rowspan="2"
                  class="align-middle"
                >
                  수정
                </b-th>
              </b-tr>
              <b-tr>
                <!-- 병합된 헤더 아래의 컬럼들 -->
                <b-th style="border-left: solid 2px #3b4253 !important;">
                  요율(%)
                </b-th>
                <b-th>상위(%)</b-th>
                <b-th style="border-left: solid 2px #3b4253 !important;">
                  요율(%)
                </b-th>
                <b-th>상위(%)</b-th>
                <b-th style="border-left: solid 2px #3b4253 !important;">
                  요율(%)
                </b-th>
                <b-th>상위(%)</b-th>
                <b-th style="border-left: solid 2px #3b4253 !important;">
                  요율(%)
                </b-th>
                <b-th>상위(%)</b-th>
              </b-tr>
            </template>
            <!-- Column: No -->
            <template #cell(no)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(role)="data">
              <b-media class="align-content-center">
                <template>
                  <b-avatar
                    size="32"
                    :src="data.item.role"
                    :text="avatarText(data.item.role)"
                    :variant="`${resolveUserRoleVariant(data.item.role)}`"
                  />
                </template>
              </b-media>
            </template>
            <template #cell(userid)="data">
              <div
                class="text-success font-weight-bold d-block text-nowrap"
                style="text-align: left !important; min-width: 7rem; text-decoration: underline; text-underline-offset: 5px;"
                @click="userClicked(data)"
              >
                {{ data.item.userid }}
              </div>
              <div
                class=""
                style="text-align: left !important; min-width: 7rem; text-underline-offset: 5px;"
              >
                {{ data.item.nickname }}
              </div>
            </template>
            <template #cell(userCount)="data">
              <div
                class="text-left"
                style="min-width: 6rem"
              >
                <div v-html="formatHtmlTotalCount(data.item)" />
              </div>
            </template>
            <template #cell(isShowDetail)="data">
              <!-- v-if="data.item.totalCount.userCnt > 0" -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                size="sm"
                @click="btnModalDetatil(data.item)"
              >
                <feather-icon
                  icon="UsersIcon"
                  size="14"
                />
              </b-button>

              <!-- Modal -->
              <template>
                <b-modal
                  id="showModalDetatil"
                  v-model="isShowModalDetatil"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  size="lg"
                >
                  <!-- Modal Header -->
                  <template #modal-header>
                    <h5 class="modal-title">
                      회원보기
                    </h5>
                    <div class="modal-actions">
                      <feather-icon
                        icon="XIcon"
                        class="ml-1 cursor-pointer"
                        @click="$bvModal.hide('showModalDetatil')"
                      />
                    </div>
                  </template>

                  <!-- Modal Body -->
                  <template #default>
                    <b-card>
                      <b-row>
                        <b-col>
                          <b-table
                            responsive
                            show-empty
                            hover
                            small
                            empty-text="No matching records found"
                            style="text-align: center; font-size: small"
                            :items="isShowModalDetatilParams"
                            :fields="userFields"
                          >
                            <!-- Column: No -->
                            <template #cell(no)="data">
                              {{ data.index + 1 }}
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                      <b-row
                        v-if="true"
                      >
                        <b-col cols="12">
                          <b-button
                            v-if="false"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            type="submit"
                            size="sm"
                            class="float-right"
                            @click.prevent="btnSave"
                          >
                            저장
                          </b-button>
                          <b-button
                            ref="reset"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="reset"
                            variant="primary"
                            size="sm"
                            class="float-right mr-2"
                            @click="$bvModal.hide('showModalDetatil')"
                          >
                            취소
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-card>
                  </template>
                </b-modal>
              </template><!-- .Modal -->
            </template>
            <template #cell(isShowBalance)="data">
              <b-button
                v-if="data.item.nickname !== userData.nickname"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                size="sm"
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="14"
                />
              </b-button>
            </template>
            <template #cell(edit)="data">
              <b-button
                v-if="data.item.nickname !== userData.nickname"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                size="sm"
                @click="btnEdit(data.item)"
              >
                <feather-icon
                  icon="EditIcon"
                  size="14"
                />
              </b-button>
            </template>
          </b-table>
        </div>
        <!-- table footer -->
        <div>
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-left justify-content-left justify-content-sm-start pl-4"
              style="margin-bottom: 0.5rem;"
            >
              <span
                class="font-small-2"
              >{{ pageFrom }}~{{ pageTo }} / 총 {{ pageOf }}
              </span>
            </b-col>
            <b-col
              cols="12"
              sm="12"
              class="d-flex align-items-center justify-content-center"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
  BFormInput, BInputGroup, BInputGroupAppend,
  BFormSelect, BButton,
  BTable, BTr, BTh, BPagination,

  BMedia,
  BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { FETCH_BRANCH_ORGANIZATION_CHART_SUB } from '@/store/branch/action'

import { createNamespacedHelpers } from 'vuex'

import { avatarText } from '@core/utils/filter'
import router from '@/router'

const branchStore = createNamespacedHelpers('branchStore')

export default {
  name: 'BranchOragnizationChartSub',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BButton,
    BTable,
    BTr,
    BTh,
    BPagination,

    BMedia,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // Sample Data
      currentURL: window.location.href,
      componentName: this.$options.name,
      userData: JSON.parse(localStorage.getItem('userData')),

      avatarText,

      // TableFilter
      searchFilter: '',

      // TableOption
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1, // 현재 페이지
      perPage: 10, // 페이지 당 표시할 항목 수
      pageFrom: 0,
      pageTo: 0,
      pageOf: 0,
      totalRows: 0, // 총 메시지 수 (서버에서 얻을 수도 있습니다)

      // Tablefields
      branchFields: [
        { key: 'no', label: 'No.', thClass: 'd-none' },
        { key: 'id', sortable: false, label: '번호', thClass: 'd-none', tdClass: 'd-none' },
        { key: 'role', sortable: false, label: '권한', thClass: 'd-none' },
        { key: 'userid', label: '아이디/닉네임', thClass: 'd-none' },
        { key: 'recommendNickname', label: '상위', thClass: 'd-none' },
        { key: 'cash', sortable: false, label: '통합캐쉬', thClass: 'd-none', formatter: 'formatNumberWithCommas' },
        { key: 'point', sortable: false, label: '포인트', thClass: 'd-none', formatter: 'formatNumberWithCommas' },
        { key: 'rate', label: '비율', thClass: 'd-none' },
        { key: 'upperRate', label: '상위 비율', thClass: 'd-none' },
        { key: 'rateSportRolling', label: '비율', thClass: 'd-none' },
        { key: 'upperRateSportRolling', label: '상위', thClass: 'd-none' },
        { key: 'rateCasinoRolling', label: '비율', thClass: 'd-none' },
        { key: 'upperRateCasinoRolling', label: '상위 비율', thClass: 'd-none' },
        { key: 'rateSlotRolling', label: '비율', thClass: 'd-none' },
        { key: 'upperRateSlotRolling', label: '상위 비율', thClass: 'd-none' },
        { key: 'userCount', label: '회원수', thClass: 'd-none' },
        { key: 'isShowDetail', label: '회원보기', thClass: 'd-none' },
        { key: 'isShowBalance', label: '정산보기', thClass: 'd-none' },
        { key: 'edit', label: '총판수정', thClass: 'd-none', formatter: value => (value == null ? '미설정' : value) },
      ],
      userFields: [
        { key: 'no', label: 'No.' },
        { key: 'id', sortable: false, label: '번호', thClass: 'd-none', tdClass: 'd-none' },
        { key: 'site', label: '사이트' },
        { key: 'userid', label: '아이디' },
        { key: 'nickname', label: '닉네임' },
        { key: 'recommendNickname', label: '상위' },
      ],

      // TableModal
      isShowModalDetatil: false,
      isShowModalDetatilParams: [],
    }
  },
  computed: {
    ...branchStore.mapGetters({
      fetchBranchOrganizationChartSub: 'fetchBranchOrganizationChartSub',
    }),

    // Table filtered Items
    filteredItems() {
      let filteredItems = []
      if (this.fetchBranchOrganizationChartSub.length) {
        const { children: childNode = [], ...rootNode } = this.fetchBranchOrganizationChartSub[0]
        filteredItems = [rootNode, ...childNode.filter(item => item.role !== 'user')]
      }
      if (this.searchFilter) {
        filteredItems = filteredItems.filter(item => Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase())))
      }
      return filteredItems
    },
    // Table filtered Items => Table Pagination Items
    paginatedItems() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      const paginatedData = this.filteredItems.slice(start, end)

      this.totalRows = this.filteredItems.length
      this.pageFrom = start + 1
      this.pageTo = start + paginatedData.length
      this.pageOf = this.totalRows

      return paginatedData
    },
  },
  methods: {
    ...branchStore.mapActions({
      $fetchBranchOrganizationChartSub: FETCH_BRANCH_ORGANIZATION_CHART_SUB,
    }),
    /* [formatter] */
    formatNumberWithCommas(value) {
      if (value == null) {
        return ''
      }
      return parseInt(value, 10).toLocaleString()
    },
    formatHtmlTotalCount(data) {
      if (!data || !data.totalCount) {
        return ''
      }
      const userCount = data.totalCount.userCount || 0
      const branchCount = data.totalCount.branchCount || 0
      const totalCount = userCount + branchCount

      return `<div
                class="text-center"
                style="min-width: 6rem"
              >
              ${totalCount} 명 (관리자: ${branchCount} 명 / 유저: ${userCount} 명)
              </div>`
    },
    // <div className="d-flex justify-content-between align-items-center">
    //   <span>전체:</span>
    //   <span>${totalCount} 명</span>
    // </div>
    // <div className="d-flex justify-content-between align-items-center">
    //   <span>관리자:</span>
    //   <span>${branchCount} 명</span>
    // </div>
    // <div className="d-flex justify-content-between align-items-center">
    //   <span>유저:</span>
    //   <span>${userCount} 명</span>
    // </div>

    btnModalDetatil() {
      // console.log(data.userid)
      // console.log(this.fetchBranchOrganizationChartSub)
      // const { children } = this.fetchBranchOrganizationChartSub.filter(item => item.userid === data.userid)
      // this.isShowModalDetatilParams = children.filter(item => item.role === 'user')
      this.isShowModalDetatil = true
    },
    btnEdit(data) {
      // console.log(data.userid)
      this.$router.push({
        name: 'branch-create',
        params: { site: data.site, userid: data.userid },
      })
    },
    resolveUserRoleVariant(role) {
      if (role === 'user') return 'secondary'
      if (role === 'branch') return 'warning'
      if (role === 'admin') return 'primary'
      if (role === 'godfather') return 'danger'
      return 'secondary'
    },
    userClicked(data) {
      localStorage.setItem('userView', JSON.stringify({ userid: data.item.userid, site: data.item.site, nickname: data.item.nickname }))
      const url = router.resolve({ name: 'apps-user-view' }).href
      // window.open(url, '_blank')
      const popupName = `userViewPopup_${new Date().getTime()}`
      const popupOptions = 'width=1800,height=1250,scrollbars=yes,resizable=yes' // 원하는 옵션 설정
      window.open(url, popupName, popupOptions)
    },
  },
}
</script>
