<template>
  <div>
    <template>
      <branch-organization-chart />
      <branch-organization-chart-sub />
    </template>
  </div>
</template>

<script>
import BranchOrganizationChart from '@/views/branch/BranchOrganizationChart.vue'
import BranchOrganizationChartSub from '@/views/branch/BranchOragnizationChartSub.vue'

export default {
  name: 'BranchList',
  components: {
    BranchOrganizationChart,
    BranchOrganizationChartSub,
  },
  data() {
    return {
      // Sample Data
      currentURL: window.location.href,
      componentName: this.$options.name,

    }
  },
  created() {
    // console.log(this.componentName, 'created()')
  },
  mounted() {
    // console.log(this.componentName, 'mounted()')
  },
  methods: {
  },
}
</script>
